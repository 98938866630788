#footer {
    background: $footer-bg;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: $footer-color-text;
    font-size: 20px;
    line-height: 1.5;
    padding-top: 10vw;
    padding-bottom: $container-gap-vertical;
    position: relative;
    z-index: 600;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;

    a {
        color: $footer-color-a;

        &:hover,
        .active {
            color: $footer-color-a-hover;
        }

        &.btn-blue:hover {
            color: #fff;
        }
    }

    .frame {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 0;
        width: 100%;
    }

    .ce-bodytext {
        width: 100%;
        max-width: 100%;
    }

    @media screen and (min-width: $layout-max-size + 10px) {
        padding-left: $container-gap-horizontal-over;
        padding-right: $container-gap-horizontal-over;
        padding-top: 200px;
    }
}

.logo-footer {
    flex-basis: 20%;
    min-width: 280px;
    width: 20%;
}

.address-footer {
    flex-basis: 72%;
    width: 72%;

    > ul {
        height: 100%;
        list-style: none;

        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: space-between;
        -ms-flex-line-pack: justify;
        align-content: space-between;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;

        > li {
            display: table;
            flex-basis: 33%;
            width: 33%;

            .helper {
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-flex-direction: row;
                -ms-flex-direction: row;
                flex-direction: row;
                -webkit-flex-wrap: wrap;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                -webkit-justify-content: flex-start;
                -ms-flex-pack: start;
                justify-content: flex-start;
                -webkit-align-content: space-between;
                -ms-flex-line-pack: justify;
                align-content: space-between;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;

                span {
                    padding-left: 10px;
                }
            }
        }
    }
}

.menu-footer {
    margin-top: 20px;
    width: 100%;

    > ul {
        display: flex;
        justify-content: flex-end;
    }

    li {
        font-weight: 600;
        &::after {
            background-color: inherit;
            content: "| ";
            white-space: pre;
            display: inline-block;
            height: 100%;
            padding: 0 10px;
            position: relative;
        }

        &:last-child::after {
            display: none;
        }
    }
}

@media screen and (max-width: 1800px) {
    .address-footer {
        > ul {
            > li {
                flex-basis: 50%;
                margin-bottom: 10px;
                width: 50%;
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    .address-footer {
        flex-basis: 65%;
        width: 65%;
    }
}

@media screen and (max-width: 1300px) {
    #footer {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .address-footer {
        flex-basis: 100%;
        margin-top: 60px;
        width: 100%;
    }

    .menu-footer {
        margin-top: 60px;
    }
}

@media screen and (max-width: 700px) {
    .address-footer {
        > ul {
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-align-content: center;
            -ms-flex-line-pack: center;
            align-content: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;

            > li {
                flex-basis: 300px;
                width: 300px;
            }
        }
    }

    .menu-footer {
        > ul {
            justify-content: center;
        }
    }
}
