@font-face {
    font-family: 'fontello';
    src: url('../Fonts/fontello.eot?6984843');
    src: url('../Fonts/fontello.eot?6984843#iefix') format('embedded-opentype'),
         url('../Fonts/fontello.woff?6984843') format('woff'),
         url('../Fonts/fontello.ttf?6984843') format('truetype'),
         url('../Fonts/fontello.svg?6984843#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}

.mail:before,
[class^="icon-"]:before,
[class*=" icon-"]:before,
[class^="fa-"]:before,
[class*=" fa-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .1em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-mail:before, .mail:before, .icon-mail-1:before { content: '\e803'; }
.icon-at:before, .fa-at:before { content: '\e800'; margin-top: -3px; }
.icon-gplus:before, .fa-google-plus:before { content: '\e801'; }
.icon-twitter:before, .fa-twitter:before { content: '\f099'; }
.icon-facebook:before, .fa-facebook:before { content: '\f09a'; }
.icon-angle-up:before, .fa-angle-up:before { content: '\f106'; }
.icon-angle-down:before, .fa-angle-down:before { content: '\f107'; }
.icon-copyright:before, .fa-copyrightat:before { content: '\f1f9'; }
.icon-whatsapp:before, .fa-whatsapp:before { content: '\f232'; }


/* roboto-100 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    src: url('../Fonts/roboto-v18-latin/roboto-v18-latin-100.eot'); /* IE9 Compat Modes */
    src: local('Roboto Thin'), local('Roboto-Thin'),
         url('../Fonts/roboto-v18-latin/roboto-v18-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../Fonts/roboto-v18-latin/roboto-v18-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
         url('../Fonts/roboto-v18-latin/roboto-v18-latin-100.woff') format('woff'), /* Modern Browsers */
         url('../Fonts/roboto-v18-latin/roboto-v18-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../Fonts/roboto-v18-latin/roboto-v18-latin-100.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-regular - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../Fonts/roboto-v18-latin/roboto-v18-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Roboto'), local('Roboto-Regular'),
         url('../Fonts/roboto-v18-latin/roboto-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../Fonts/roboto-v18-latin/roboto-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../Fonts/roboto-v18-latin/roboto-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../Fonts/roboto-v18-latin/roboto-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../Fonts/roboto-v18-latin/roboto-v18-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
  }
  /* roboto-700 - latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('../Fonts/roboto-v18-latin/roboto-v18-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Roboto Bold'), local('Roboto-Bold'),
         url('../Fonts/roboto-v18-latin/roboto-v18-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../Fonts/roboto-v18-latin/roboto-v18-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../Fonts/roboto-v18-latin/roboto-v18-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../Fonts/roboto-v18-latin/roboto-v18-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../Fonts/roboto-v18-latin/roboto-v18-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
  }
